<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewDataVisits"
      :key="row.id"
      tag="li"
      no-body
      @click.stop.prevent="openModalDetailByRow(row)"
    >
      <b-media-body>
        <div class="mail-details">
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <h5
                  v-if="row.pid"
                  class=""
                >
                  {{ row.pid }}
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="3"
                class="mt-1"
              >
                <span
                  v-if="row.nome_angariacao"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.nome_angariacao }}
                </span>
                <br>
                <div
                  v-if="row.nome_angariacao"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Angariador') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3"
                class="mt-1"
              >
                <span
                  v-if="row.name_lead"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.name_lead }}
                </span>
                <br>
                <span
                  v-if="row.contacto_lead"
                >
                  <feather-icon
                    icon="PhoneCallIcon"
                  />
                  {{ row.contacto_lead }}
                </span>
                <div
                  v-if="row.name_lead"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $tc('Lead', 1) }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="2"
                class="mt-1"
              >
                <span
                  v-if="row.data_visita"
                >
                  {{ row.data_visita }}
                </span>
                <div
                  v-if="row.data_visita"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Data da visita') }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>

      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaBody, VBTooltip, BRow, BCol, BContainer,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    BContainer,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    openModalDetailByRow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('crm_proposals', ['listviewDataVisits']),
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {},
}
</script>
